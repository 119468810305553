import React from 'react'
import { type LucideProps } from 'lucide-react'

const CustomChatgpt: React.FC<LucideProps> = (props: LucideProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      d="M5.274 4.463a4.937 4.937 0 0 0-3.611 2.375 4.765 4.765 0 0 0 .694 5.75m2.917-8.125a4.747 4.747 0 0 0-.203 1.376v3.269c0 .772.411 1.485 1.08 1.871l2.387 1.382M5.274 4.463C5.879 2.461 7.77 1 10.012 1a4.97 4.97 0 0 1 3.884 1.847M2.356 12.59a4.742 4.742 0 0 0 .296 4.249c1.12 1.9 3.358 2.775 5.43 2.287M2.357 12.59c.32.33.692.62 1.114.859l2.942 1.663c.654.37 1.452.374 2.11.01l2.448-1.352m-2.889 5.356a5.004 5.004 0 0 0 1.32-.516l2.908-1.645a2.162 2.162 0 0 0 1.098-1.86l.028-2.696m-5.354 6.717A4.97 4.97 0 0 0 11.988 21c2.24 0 4.133-1.46 4.739-3.463m0 0a4.937 4.937 0 0 0 3.61-2.375 4.765 4.765 0 0 0-.692-5.75m-2.918 8.125c.131-.436.202-.898.202-1.376v-3.269c0-.772-.411-1.485-1.08-1.871l-2.385-1.38m6.18-.229a4.742 4.742 0 0 0-.294-4.25c-1.364-2.315-4.386-3.108-6.75-1.771L9.693 5.035a2.162 2.162 0 0 0-1.098 1.86l-.028 2.696m11.079-.179a4.927 4.927 0 0 0-1.116-.86L15.587 6.89a2.162 2.162 0 0 0-2.11-.01L11.03 8.23m0 0 2.435 1.41M11.03 8.23 8.566 9.59m4.898.05-.028 2.767m0 0L10.97 13.77m0 0-2.433-1.408m0 0 .028-2.77"
    />
  </svg>
)

export default CustomChatgpt
