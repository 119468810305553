import React from "react";
import { type LucideProps } from "lucide-react";

const styles = {
  border: "1px solid #000",
  margin: "auto",
}

const Skully: React.FC<LucideProps> = (props: LucideProps) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={172}
    height={54}
    style={styles}
    viewBox="0 0 232.000000 41.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >

    <g transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)"
      fill="white" stroke="none">
      <path d="M176 372 c-104 -3 -125 -18 -126 -89 0 -63 31 -81 141 -81 95 1 128
-7 130 -28 5 -51 8 -49 -76 -54 -97 -7 -127 -1 -140 28 -5 13 -16 20 -23 17
-8 -3 -18 -5 -23 -5 -5 0 -9 -14 -9 -31 0 -30 19 -59 39 -59 6 0 11 -4 11 -8
0 -10 206 -8 237 3 44 14 59 100 26 156 -17 29 -17 31 -1 43 14 10 16 19 8 44
-17 62 -39 69 -194 64z m128 -62 c2 -3 7 -17 9 -30 l5 -25 -93 0 c-54 0 -91 4
-87 9 3 5 -2 7 -11 3 -14 -5 -17 0 -17 23 l0 29 94 -2 c52 -1 97 -4 100 -7z"/>
      <path d="M704 365 c-16 -6 -35 -19 -43 -28 -7 -9 -17 -14 -22 -11 -5 3 -9 -2
-9 -10 0 -9 -6 -16 -14 -16 -8 0 -16 -7 -20 -15 -3 -8 -11 -15 -18 -15 -7 0
-21 -11 -32 -24 -21 -29 -46 -24 -40 7 2 12 1 43 -2 70 -6 43 -9 47 -33 47
l-27 0 0 -152 1 -153 24 -7 c31 -9 33 -7 36 37 5 63 86 125 116 88 6 -7 8 -13
5 -13 -4 0 5 -11 20 -25 15 -14 24 -25 20 -25 -3 0 2 -9 12 -19 9 -11 23 -27
30 -35 7 -9 26 -16 43 -16 27 0 30 3 23 23 -4 12 -15 28 -25 35 -11 7 -19 19
-19 26 0 6 -18 31 -40 54 -22 22 -40 48 -40 57 0 8 20 30 45 47 25 18 45 35
45 40 0 4 7 8 15 8 22 0 19 27 -4 33 -10 2 -31 -1 -47 -8z"/>
      <path d="M1086 359 c19 -21 22 -196 5 -224 -11 -17 -24 -20 -99 -20 -121 0
-122 1 -122 142 l0 113 -29 0 -30 0 1 -121 c0 -120 11 -169 36 -169 7 0 10 -5
7 -10 -4 -6 8 -11 27 -11 198 -9 240 -3 262 40 13 25 19 98 17 222 l-1 47 -44
4 c-40 3 -43 1 -30 -13z"/>
      <path d="M1255 372 c-19 -3 -20 -11 -21 -160 l-1 -157 161 2 c89 1 164 2 167
2 4 1 6 14 5 30 l-1 28 -135 -1 -135 -1 0 131 c0 81 -4 130 -10 130 -5 0 -19
-2 -30 -4z"/>
      <path d="M1902 363 c6 -7 7 -13 2 -13 -4 0 1 -6 12 -14 10 -8 19 -19 19 -24 0
-6 11 -21 25 -34 14 -13 42 -45 62 -72 34 -44 37 -53 33 -97 -4 -48 -3 -49 26
-49 23 0 29 5 30 23 3 71 9 101 21 108 7 5 10 9 6 9 -4 0 13 21 37 47 25 26
45 51 45 55 0 4 12 20 26 35 l26 28 -43 3 c-33 3 -45 -1 -53 -15 -6 -10 -11
-21 -11 -24 0 -3 -12 -17 -27 -31 -16 -14 -28 -30 -28 -36 0 -6 -9 -16 -19
-22 -16 -8 -21 -5 -33 20 -7 17 -17 30 -23 30 -5 0 -22 18 -37 39 -23 32 -35
40 -67 43 -31 3 -37 1 -29 -9z"/>
      <path d="M1643 213 l0 -158 161 0 161 1 3 23 c2 13 -1 27 -6 30 -6 3 -67 6
-136 5 l-126 -2 -1 107 c-2 151 0 146 -30 149 l-26 3 0 -158z"/>
    </g>
  </svg>
);

export default Skully;
